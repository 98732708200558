import { Link } from 'react-router-dom';
import './home.css';


const Home = () => {

    return(
        <section id="page-home">
            <div className="hero">
                <video id="background-video" autoPlay loop muted poster="assets/videos/bg-video.mp4">
                <source src="assets/videos/bg-video.mp4" type="video/mp4" />
                </video>
            </div>

           
           
            {/* About us start */}
            <div className='about-us'>
             
                <div className='container'>
                    <div className='right'>
                        <h4>ABOUT US</h4>
                        <h2 className='section-title'>TACTUS<sup>®</sup> VENTURES INC</h2>
                        <p>
                        We create objects and apparel that serve a higher purpose-inspired by the timeless wisdom of eras past, the most vital ideology shifts of the present, and the edifying spirit of human ingenuity that lights the way for a brighter future. Inspired by the spiritual energy of India, European aesthetics, and a shared vision for the fusion of eco-friendly fashion with technology, the idea for the Tactus<sup>®</sup> brand was first conceived in 2019.</p>
                        
                        <div className='buttons'>
                            <Link to="/about-us" className='btn btn-primary'>About Tactus<sup className='sup-black'>®</sup> Ventures</Link>
                            <Link to="/about-us" className='btn btn-outline-primary'>About Leadership</Link>
                        </div>
                    </div>
                </div>

            </div>
            {/* about us section */}



             {/* ---Porduct Section Start--- */}

             <div className='products'>
                <div className='container'>
                <div className='top'>
                <h2 className='section-title'>Our Inventions </h2>
                <p>The company is committed to inventing new and innovative products that enhance the quality of life for consumers. We specialize in developing products that are both functional and stylish, and our research team works tirelessly to create cutting-edge designs that meet the ever-evolving needs of consumers. We take pride in its extensive research and development efforts, which enable them to create products that are not only high-quality but also sustainable and environmentally friendly. We use the latest technology and materials to ensure that our products are durable, safe, and effective. Tactus<sup>®</sup> Venture is dedicated to improving people's lives through the creation of innovative lifestyle products. Their focus on research and development ensures that they stay ahead of the competition and continue to provide products that are both functional and fashionable.

</p>
                    </div>
                    <div className='products-list'>
                    <div className='product-list-opacity'>
                        </div>
                        <div className='product-card'>
                           <div>
                               <img src='assets/img/products/Cartesia-1.png' alt='product-1' />
                           </div>
                            <Link to={'/Products'}>                            
                                <h3 className='product-title'>Cartesia</h3>
                            </Link>
                        </div>

                        <div className='product-card'>
                           <div>
                               <img src='assets/img/products/mrija-1.png' alt='product-1' />
                           </div>
                            <Link to={'/Products'}>
                               <h3 className='product-title'>Mrija</h3>
                            </Link>
                        </div>

                        <div className='product-card'>
                           <div>
                               <img src='assets/img/products/Japa.png' alt='product-1' />
                           </div>
                           <Link to={'/Products'}>
                              <h3 className='product-title'>Japa</h3>
                           </Link>
                        </div>

                        <div className='product-card'>
                           <div>
                              <img src='assets/img/products/Jnana.png' alt='product-1' />
                          </div>
                          <Link to={'/Products'}>
                             <h3 className='product-title'>Jnana</h3>
                          </Link>         
                        </div>

                        <div className='view-btn'>
                           <Link to='/Products' className='btn'>
                                <i className="fa-solid fa-arrow-right"></i>
                           </Link>
                        </div>
                                        {/* <div className='view'>
                                            <a href='#'>
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </a>
                                            {/* <h3 className='product-title'>View All</h3>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
               

            {/* careers section */}
            {/* <div className='careers'>
               
                <div className='careers-left'>
                    
                </div>


                <div className='careers-right'>
                    <h4>ABOUT US</h4>
                    <h2 className='section-title'>Work <span> With Us </span></h2>
                    <p>In these times of ever-evolving technology and a connected world, Zenatix is looking for people who are poised to face tomorrow and the challenges it may bring.</p>

                    <button className='btn btn-primary'>Careers</button>
                </div>
             
            </div> */}
            {/* careers ends */}
            

            {/* Our Expertiese */}
            <div className='section-expertise'>
                <div className='container'>
                    <h2 className='section-title'>Our <span>Expertiese</span></h2>


                    <div className='cards-wrapper'> 


                        <div className='card'>
                            {/* <img src='assets/img/icons/search.svg' alt="card-image" /> */}
                            <i className='fas fa-microscope'></i>

                            <div className='content'>
                                <h3>Research & Development</h3>
                                <p>The company invests heavily in research and development to create new and innovative products, improve existing products, and enhance customer experiences. </p>
                                  {/* <p>The company's research and development efforts are aimed at developing new technologies, materials, and processes that can help them meet customer needs and expectations.The company's research and development team comprises experts from various fields who work collaboratively to identify new opportunities and develop solutions that can deliver real value to customers. They use the latest tools, technologies, and methodologies to design and test prototypes and validate new concepts.Overall, the company recognizes that research and development are critical for innovation, growth, and sustainability. The company's focus on research and development ensures that they can continue to deliver high-quality products and services that meet the evolving needs of customers and stay ahead of the competition.</p> */}
                            </div>

                        </div>


                        <div className='card'>
                            {/* <img src='assets/img/icons/value.svg' alt="card-image" /> */}
                            <i className='fas fa-wifi'></i>

                            <div className='content'>
                                <h3>Internet Of Things(IOT)</h3>
                                <p>We believe that IoT has the potential to transform many industries, by providing real-time data and insights that can help user make data-driven decisions, optimize operations, and enhance experiences.</p>
                                {/* <p>The company's IoT solutions are designed to provide user with real-time insights into their operations, allowing them to identify potential issues and make informed decisions quickly. IoT can also help businesses reduce costs by improving operational efficiency, enhancing supply chain management, and optimizing energy usage.Furthermore, IoT has the potential to improve people's lives by enabling remote monitoring and management.We recognizes the importance of IoT and is committed to developing innovative IoT solutions that can deliver real value to businesses and consumers. The company's focus on IoT ensures that they stay at the forefront of technological innovation and can create products and services that meet the evolving needs of customers in today's digital age.</p> */}
                            </div>
                        </div>

                        <div className='card'>
                            {/* <img src='https://www.solulab.com/wp-content/uploads/2022/05/Frame-7.png' alt="card-image" /> */}
                            <i className='fas fa-screwdriver-wrench'></i>


                            <div className='content'>
                                <h3>Hardware Designing</h3>
                                <p>Tactus
                                    
                                    <sup>®</sup> recognizes that hardware designing is crucial for creating high-quality products that meet customer needs and expectations. The company's hardware designing process involves identifying customer requirements.</p>
                                {/* <p> developing detailed specifications, and creating designs that meet those requirements. Tactus hardware designing team comprises experts in various fields, including engineering, manufacturing, and quality control, who work collaboratively to create innovative hardware designs that are technically sound, reliable, and meet safety standards. We focus on hardware designing ensures that they can create products that are not only functional but also aesthetically pleasing and user-friendly. We uses the latest tools and technologies to design and test hardware prototypes, ensuring that they are optimized for performance, durability, and cost-effectiveness. Overall, the company recognizes that hardware designing is critical for creating successful products that meet customer needs and expectations. The company's expertise in hardware designing ensures that they can create innovative and high-quality products that meet the evolving needs of customers in today's rapidly changing market.</p> */}
                            </div>

                        </div>

                        <div className='card'>
                            {/* <img src='https://www.solulab.com/wp-content/uploads/2022/05/Frame-7.png' alt="card-image" /> */}
                            <i className='fas fa-computer'></i>


                            <div className='content'>
                                <h3>Software Designing</h3>
                                <p>Tactus<sup>®</sup> recognizes that software designing is crucial for creating high-quality products that meet customer needs and expectations. The company's software designing process involves identifying customer requirements.</p>
                            </div>

                        </div>

                        <div className='card'>
                            {/* <img src='https://www.solulab.com/wp-content/uploads/2022/05/Frame-7.png' alt="card-image" /> */}
                            <i className='fas fa-hands-holding'></i>


                            <div className='content'>
                                <h3>Values</h3>
                                <p>Tactus<sup>®</sup> recognizes that values are essential for building a strong culture and creating a positive impact on the community, employees, and stakeholders.Values include innovation, excellence, integrity, respect, teamwork, and customer focus.</p>
                            </div>

                        </div>

                        <div className='card'>

                            <i className='fas fa-person'></i>
                            {/* <img src='assets/img/icons/customer.svg' alt="card-image" /> */}

                            <div className='content'>
                                <h3>Customer Centric</h3>
                                <p>Customers are at the heart of its Tactus
                                
                                <sup>®</sup> and meeting their needs and expectations is critical for success. The company is committed to delivering exceptional value to its customers by understanding their needs and providing innovative solutions that solve their problems.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* our expertiese ends */}

            {/*  -----------------  blogs  --------------------- */}
            {/* <div className='section-blog'>
                <div className='container'>
                    <h2 className='section-title'>Press <span>& Media</span> </h2>

                    <div className='blog-articles'>
                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/Capture-1.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a>

                                <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div>
                            </div>
                        </div>

                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/Capture.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a> 


                                   <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div> 
                            </div>
                        </div>


                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/oxyzo-fintech-team.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a>

                                <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default Home;                