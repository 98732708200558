import React from 'react'; 
import './about.css'; 
import Slider from "react-slick";


const About = () => {

  let leftArrow = document.querySelector('.left-btn'); 

  var settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    autoPlay: true, 
    arrows: true,  
    slidesToScroll: 1, 
    autoplaySpeed: 2000,


  };
  return (
    <section id='page-about'>
        <div className='about-header' id='about-page-header'>
             <h1>About Us</h1>
        </div>

           <div className='container'>
          <div className='about-content'>
              <p>
                We create objects and apparel that serve a higher purpose-inspired by the timeless wisdom of eras past,
                the most vital ideology shifts of the present, and the edifying spirit of human ingenuity that lights
                the way for a brighter future. Inspired by the spiritual energy of India, European aesthetics, and a 
                shared vision for fusion eco-friendly fashion with technology, the idea for Tactus<sup>®</sup> brand was first
                conceived in 2019. 
              </p>
          </div>

          <div className='brand-philosophy'>
            <div className='head'>
              <h1>BRAND PHILOSOPHY</h1>
            </div>
            <div className='brand-content'>
              <p>
                A cohesive, unified, empowered and confident brand makes consious, thoughtful and intentional brand
                decisions across disciplines. They carefully hone details in a way that creates unity between brand philosophy,
                messaging, language choices, products details, public perception, aesthetics and apprearance.
              </p>
            </div>
          </div>
        </div>

        <div className='leader'>
          <div className='team'>
          <h1>TEAM</h1>
          </div>
          <div className='promoter'>
            <h1>MINDS BEHIND TACTUS<sup>®</sup> VENTURES INC</h1>
          </div>
        </div>

        <div className='container'>
          <hr></hr>
        </div>

        <div className='leaders-wrapper'>
        <div className='container'>
              {/* <div>
                <button className='left-btn'>left arrow</button>
              </div> */}
              {/* <Slider {...settings}> */}
                <div className='leader-card'>
                      <div className='leader-showcase'>
                        <img src='assets/img/karanbhasin.jpg'/>
                      </div>

                      <div className='leader-footer'>
                          <a href='#'>KARAN BHASIN</a>
                          <p>FOUNDER, MD & CEO</p>
                      </div>
                  </div>
                  
                

                {/* </Slider> */}
          </div>
          <div className='container'>
              {/* <div>
                <button className='left-btn'>left arrow</button>
              </div> */}
              {/* <Slider {...settings}> */}
              <div className='leader-card'>
                    <div className='leader-showcase'>
                       <img src='assets/img/jogeshteam.jpg'/>
                    </div>

                    <div className='leader-footer'>
                        <a href='#'>JOGESH BHASIN</a>
                        <p>MENTOR</p>
                    </div>
                </div>
                
                <div className='leader-card'>
                    <div className='leader-showcase'>
                       <img src='assets/img/Sunita.png'/>
                    </div>

                    <div className='leader-footer'>
                        <a href='#'>SUNITA BHASIN</a>
                        <p>DIRECTOR</p>
                    </div>
                </div>

                <div className='leader-card'>
                    <div className='leader-showcase'>
                       <img src='assets/img/maheshprabhaker.jpg'/>
                    </div>

                    <div className='leader-footer'>
                        <a href='#'>MAHESH PRABHAKAR</a>
                        <p>EXECUTIVE DIRECTOR<br></br>(OPERATIONS)</p>
                    </div>
                </div>

                
            

               
                {/* </Slider> */}
          </div>

          <div className='container'>
              {/* <div>
                <button className='left-btn'>left arrow</button>
              </div> */}
              {/* <Slider {...settings}> */}
                <div className='leader-card'>
                      <div className='leader-showcase'>
                        <img src='assets/img/Pulkit.png'/>
                      </div>

                      <div className='leader-footer'>
                          <a href='#'>PULKIT TIWARI</a>
                          <p>CTO<br></br>(CHIEF TECHNICAL OFFICER)</p>
                      </div>
                  </div>


                  <div className='leader-card'>
                      <div className='leader-showcase'>
                        <img src='assets/img/sohel.png'/>
                      </div>

                      <div className='leader-footer'>
                          <a href='#'>MUNSHI MUHAMADSOHEL ABDULHAQ</a>
                          <p>HARDWARE DESIGN ENGINEER</p>
                      </div>
                  </div>
          </div>

          <div className='container'>
              {/* <div>
                <button className='left-btn'>left arrow</button>
              </div> */}
              {/* <Slider {...settings}> */}
                <div className='leader-card'>
                      <div className='leader-showcase'>
                        <img src='assets/img/gayatri.png'/>
                      </div>

                      <div className='leader-footer'>
                          <a href='#'>GAYATRI IYER</a>
                          <p>HR Executive</p>
                      </div>
                  </div>


                  <div className='leader-card'>
                      <div className='leader-showcase'>
                        <img src='assets/img/Prerna .jpeg'/>
                      </div>

                      <div className='leader-footer'>
                          <a href='#'>PRERNA GAIKWAD</a>
                          <p>BRAND MANAGER (MARKETING)</p>
                      </div>
                  </div>
          </div>
        </div>

          <div className='team-wrapper'>
          <h1>OUR EXCEPTIONAL TEAM</h1>
          <hr></hr>
        <div className='container'>
          {/* <Slider {...settings}> */}
          <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/ramisateam.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>RAMISA BAYGEREEVA</a>
                        <p>PRODUCT MANAGER<br></br>(RUSSIA & ASIA)</p>
                    </div>
          </div>

          <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/cassandrateam.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>CASSANDRA SMOLCIC</a>
                        <p>PRODUCT MANAGER<br></br>(USA & CANADA)</p>
                    </div>
          </div>

          <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/emilyteam.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>EMILY SIEBERT</a>
                        <p>BUSSINESS  DEVELOPMENT<br></br> MANGER (EU)</p>
                    </div>
          </div>

                <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/bhavikteam.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>BHAVIK DOSHI</a>
                        <p></p>
                    </div>
                </div>
                {/* <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/karanbhasin.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>xyz</a>
                        <p></p>
                    </div>
                </div>
                <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/karanbhasin.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>NISHANT DOSHI</a>
                        <p>FOUNDER, MD & CEO</p>
                    </div>
                </div>
                <div className='team-card'>
                    <div className='team-showcase'>
                       <img src='assets/img/karanbhasin.jpg'/>
                    </div>

                    <div className='team-footer'>
                        <a href='#'>DINESH RAJGOR</a>
                        <p>FINANCE/ACCOUNTS &<br></br> TAXATION</p>
                    </div>
                </div> */}
                
          {/* </Slider> */}
          </div>
        </div>

        <div className='team-members-wrapper'>
            <hr></hr>
            <h1 className='emplyees-name'>
              OUR TEAM
            </h1>

          <div className='container'>
            <div className='member-name'>
              <h2>
              Dharmik Sapte
              </h2>
              <div className='member-designation'>
                <p>
                Sr. Videographer
                </p>
              </div>
            </div>

            <div className='member-name'>
              <h2>
              Ranveer Gandhi
              </h2>
              <div className='member-designation'>
                <p>
                Assistant Motion Graphics Designer
                </p>
              </div>
            </div>

            <div className='member-name'>
              <h2>
                Dhiraj Zore
              </h2>
              <div className='member-designation'>
                <p>
                Assistant Design Engineer
                </p>
              </div>
            </div>

           

            <div className='member-name'>
              <h2>
              Raj Pawar
              </h2>
              <div className='member-designation'>
                <p>
                 Trainee Backend Developer 
                </p>
              </div>
            </div>

            <div className='member-name'>
              <h2>
              Jayesh Dalvi
              </h2>
              <div className='member-designation'>
                <p>
                Trainee Frontend Developer (Mobile Application) 
                </p>
              </div>
            </div>

            <div className='member-name'>
              <h2>
                Sonali Ambre
              </h2>
              <div className='member-designation'>
                <p>
                Staff Accountant
                </p>
              </div>
            </div>

            <div className='member-name'>
              <h2>
              Riya Khamkar
              </h2>
              <div className='member-designation'>
                <p>
                Admin Assistant
                </p>
              </div>
            </div>

            <div className='member-name'>
              <h2>
              Sarfraz Ahmed Ansari
              </h2>
              <div className='member-designation'>
                <p>
                Executive IT Administration
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='last-line'>
          <p>
            WE THRIVE IN MAKING PRODUCTS THAT<br></br> ENRICH THE SOUL.
          </p>
        </div>
    </section>
  )
}

export default About; 
