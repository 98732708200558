import React from 'react';
import "./footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
        <div className='container'>
            
             {/* <div className='top'> */}
                {/* <h2>Enquiry</h2> */}

                {/* <div className='footer-form'>
                    <form>
                        <input type="text" placeholder='Name'/>
                        <input type="text" placeholder='Email'/>
                        <input type="number" placeholder='Phone'/>


                        <button>Submit</button>
                    </form>
                </div> */}


            {/* </div> */}


            <div className='middle'>
                <div className='footer-middle-1 contact'>
                    <h2 className='footer-section-heading'>Contact</h2>

                    <div className='content'>
                        <p>info@tactusventures.com</p>
                        <p> 022 69368000</p>
                    </div>
                </div>

                <div className='footer-middle-1 office'>
                     <h2 className='footer-section-heading'>Offices</h2>

                     <div className='content'>
                        <h2>USA Office: </h2>
                        <p>
16192 Coastal Highway, <br /> Lewes,  Delaware 19958, <br />
United States of America </p>
                     </div>

                     <div className='content'>
                        <h2>INDIA Office:  </h2>
                        <p>
                        Compound No 6, Plot No 28,  <br />
                        Marol Co-operative, Industrial Estate, <br />
                        Opp prodocs Solutions Pvt. Ltd,<br />
                        Marol, Andheri-East, <br></br>
                        Mumbai, Maharashtra 400059</p>
                     </div>

                </div>

                <div className='footer-middle-1 explore'>
                    <h2 className='footer-section-heading'>More</h2>
                    <div className='content'>
                        <ul>
                            <li><Link to="/about-us#about-page-header">About us</Link></li>
                            <li><Link to='contact-us'>Contact us</Link></li>
                            <li><Link to='/blog'>Blog</Link></li>
                            <li><Link to='/about-us'>Team Members</Link></li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className='bottom'>
                <p>©TACTUS<sup>®</sup> VENTURES INC . All Rights Reserved</p>
            </div>
        </div>
    </footer>
  )
}

export default Footer
