import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';
import About from './pages/About/About';
import Products from './pages/Products/Products';
import Blog from './pages/Blog/Blog';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SingleProduct from './pages/Products/SingleProduct';
import SingleBlog from './pages/Blog/SingleBlog';
import own from './pages/Own/own';

function App() {
  return (
    <div className="App">
        <Router>
            <Header />
            <Routes>  
                <Route path='/' element={<Home />} />
                <Route path='/contact-us' element={<Contact />} />
                <Route path='/about-us' element={<About />} />
                <Route path='/products' element={<Products />} />
                <Route path='/blog' element={<Blog/>} />
                <Route path='/products/:product' element={<SingleProduct/>}/>
                <Route path='/blog/:blog' element={<SingleBlog/>}/>
                <Route path='/own/:own' element={<own/>}/>
            </Routes>

            <Footer />
            
        </Router>
    </div>
  );
}

export default App;
