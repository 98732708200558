import { Link, useParams } from 'react-router-dom';

import './Avyana.css';

const SingleProduct = () => {
  
    return(
        <section id="page-single">
            <div className='single-img'>
                <h2>Avyanass</h2>
                <img src='/assets/img/products/Cartesia-1.png' ></img>
            </div>
        </section>
    ) 
}
export default SingleProduct;
