import { Link } from 'react-router-dom';
import { useState } from 'react';
import './Products.css';
import { useNavigate } from 'react-router-dom';


const Products = () => {
    const [prodModel, setProdModel] = useState(false); 
    const [product, setProduct] = useState({
        name: '', 
        image: "", 
        content: ''
    })
    const navigate = useNavigate(); 

    function openSingleProduct (e, prodName) {
       
        navigate(`/products/${prodName}`); 
    }

    let display = prodModel === true?"block": "none"; 
    let modalStyle = {
        display, 
    }

 

    let content  = { 
        
        Mrija: <>
            <p><h2 className='mrija'>{product.name}</h2><br></br><span className='meaning'>Meaning purification,cleansing and ablution.</span> <br></br>
                   Mrija is a tea line product of Tactus <sup>R</sup> Ventures.
                   The majestic herbs of this tea designate to liberate its imbiber from the taint of the elements.<br></br><span className='chakra'>'Chakra Balancing'</span><br></br>is a rudimental sadhana in yoga to transcend the inhibitions of the physical and to become available to a dimension beyond the physical.<br></br> <h3>Why Mrija?</h3> <br></br> Tea was pristinely a medicine and later grew into the popular beverage it is today. Its protective health benefits cannot be stressed enough as modern man has withal discovered its potency of antioxidants.<br></br>
                   <h3>Transcend beyond the realm of imagination</h3><br></br> Give your body the time, space and perfect conditions to recuperate balance and vitality with every sip of tea. <h3>Herbal teas have plenty of benefits for body and mind and it helps in balancing chakras within.</h3> <br></br><h3>Wheels of human body In Sanskrit</h3>Chakras or ‘wheels’ are centres of illimitable energy located in the human body. Align all seven chakras of the body with each other and balance their energies. Transmute your cup of Mrija into your tranquilly mindful moment of the day.Everything that surrounds us is made of energy, including our bodies. Chakras are the focal point of energy in our bodies and the word itself means “wheel” in Sanskrit. Mrija teas have plenty of benefits for body and mind and it helps in balancing chakras within. Handpicked, withered, rolled and fermented; our teas are made by experienced tea makers using traditional processes. </p>
        </>, 
        OwnShoe: <><p><h2 className='ownshoe'>{product.name}</h2><br></br><h3>Introducing</h3> our latest innovation, the Own Shoe - a cutting-edge shoe designed to enhance your active lifestyle. With advanced features such as vital tracking, gait analysis, and GPS tracking, the Smart Shoe is the perfect companion for fitness enthusiasts and athletes.Own Shoe is equipped with sensors that can track your vitals, including heart rate, steps taken, and calories burned. The data is displayed in real-time on your smartphone, giving you instant feedback on your workout performance. The shoe's gait analysis technology provides you with insights into your stride and foot placement, helping you optimize your running form and reduce the risk of injury.In addition to its vital tracking and gait analysis capabilities, the Smart Shoe is GPS enabled, allowing you to track your location and distance covered. Whether you're running in the park or hiking in the mountains, the Smart Shoe will help you navigate your surroundings and stay on track.The Smart Shoe is designed for both style and functionality, featuring a sleek and modern design that is available in a range of colors and sizes. The shoe is made from high-quality materials, ensuring durability and comfort even during the most intense workouts.The Shoe is easy to use, with a simple and intuitive interface that allows you to control its features using your smartphone. The shoe is also compatible with a range of fitness apps, allowing you to integrate your data and track your progress over time.</p> 
        </>,

Japa:<><p><h2 className='japa'>{product.name}</h2><br></br><span className='meaning'>Beat The Clock.</span> <br></br>
Scientists say the earth is spinning faster than it has in decades. .” We try to think and plan with deliberation, but our thoughts drift, and this mind-wandering -- dwelling on the past and fretting about the future -- is proven to make us less happy. Sadhus, monks, medical experts and successful CEOs have long attested to the spiritual, cognitive, and emotional benefits of meditation.Tactus<sup>R</sup> has created a dynamic tool that not only keeps track of the time, but also helps you “dial it down.” The watch-face of the Japa timepiece serves as a small-scale Himalayan singing bowl both in its form and ability to emanate gong tones to induce meditation.For thousands of years singing bowls and temple gongs have been used for ancient to modern day rituals to invoke deep relaxation , promote mental clarity, and stimulate regeneration.These pieces are fixtures in the art of sound of healing, which is known to restore harmony and alter our brainwaves for the better.<br></br><h3> Cast In Any Of The Seven Sacred Metals</h3><br></br>The Japa watch can be cast in any of the seven sacred metals representative of a planet to create gongs and singing bowls: Gold (sun), Silver (moon), Mercury (Mercury), Copper (Venus), Iron (Mars), Tin (Jupiter), and Lead (Saturn. This elegant eco-friendly timepiece channels the calming acoustics of the ancient East to signal tranquility, reminding you to make an appointment with your mantra and take a moment to strike your own balance. With the Japa watch, you will carry good vibrations everywhere you go.Everything that surrounds us is made of energy, including our bodies. Chakras are the focal point of energy in our bodies and the word itself means “wheel” in Sanskrit. Mrija teas have plenty of benefits for body and mind and it helps in balancing chakras within. </p>
</>,

Jheel:<><p><h2 className='jheel'>{product.name}</h2><br></br><span className='meaning'>Completeness Of Experience</span> <br></br>
In ancient Vedic times, the Rishis referred to illusion a Maya or that which is not. Feni is a tropical liquor in every sense of the word. This Indian brandy, for lack of better term, is made from the toddy of a coconut palm. Particularly from the region of Goa.The concept of maya is deeply integrated with our sensory experience. Maya describes an experience that could be considered both real and unreal.
<h3>Thrid Eye</h3>
Clearly Maya, or illusion is there everywhere around us.
‘Third eye’ and the spiritual centre, which interacts with
The rational mind in order to deepen our intuiti8ve insight
To see beyond the veil of illusion that is called ‘’Maya.” 
</p>
</>,

Svarga :<><p><h2 className='svarga'>{product.name}</h2><br></br><span className='meaning'>Svarga Watch</span> <br></br>
The Svarga watch will not only tell its wearer the lunar phases but it also reveals the moon’s corresponding Zodiac sign on any given day. This enigmatic piece compels you to keep an eye on both space and time, reminding you to keep in touch.Everything that surrounds us is made of energy, including our bodies. Chakras are the focal point of energy in our bodies and the word itself means “wheel” in Sanskrit. Mrija teas have plenty of benefits for body and mind and it helps in balancing chakras within.Handpicked, withered, rolled and fermented; our teas are made by experienced tea makers using traditional processes.</p>
</>,

Jnana:<><p><h2 className='jnana'>{product.name}</h2><br></br><span className='meaning'>Beat The Clock.</span> <br></br>
Mrija is a tea line product of Tactus <sup>R</sup> Ventures.
The majestic herbs of this tea designate to liberate its imbiber from the taint of the elements.<br></br><span className='chakra'>'Chakra Balancing'</span><br></br>is a rudimental sadhana in yoga to transcend the inhibitions of the physical and to become available to a dimension beyond the physical.<br></br> <h3>Why Mrija?</h3> <br></br> Tea was pristinely a medicine and later grew into the popular beverage it is today. Its protective health benefits cannot be stressed enough as modern man has withal discovered its potency of antioxidants.<br></br><h3>Transcend beyond the realm of imagination</h3><br></br> Give your body the time, space and perfect conditions to recuperate balance and vitality with every sip of tea. Herbal teas have plenty of benefits for body and mind and it helps in balancing chakras within. Wheels of human body In Sanskrit, Chakras or ‘wheels’ are centres of illimitable energy located in the human body. Align all seven chakras of the body with each other and balance their energies. Transmute your cup of Mrija into your tranquilly mindful moment of the day.Everything that surrounds us is made of energy, including our bodies. Chakras are the focal point of energy in our bodies and the word itself means “wheel” in Sanskrit. Mrija teas have plenty of benefits for body and mind and it helps in balancing chakras within. Handpicked, withered, rolled and fermented; our teas are made by experienced tea makers using traditional processes. </p>
</>,

Nuqi:<><p><h2 className='nuqi'>{product.name}</h2><br></br><span className='meaning'>Beat The Clock.</span> <br></br>
Mrija is a tea line product of Tactus<sup>®</sup> Ventures.
The majestic herbs of this tea designate to liberate its imbiber from the taint of the elements.<br></br><span className='chakra'>'Chakra Balancing'</span><br></br>is a rudimental sadhana in yoga to transcend the inhibitions of the physical and to become available to a dimension beyond the physical.<br></br> <h3>Why Mrija?</h3> <br></br> Tea was pristinely a medicine and later grew into the popular beverage it is today. Its protective health benefits cannot be stressed enough as modern man has withal discovered its potency of antioxidants.<br></br><h3>Transcend beyond the realm of imagination</h3><br></br> Give your body the time, space and perfect conditions to recuperate balance and vitality with every sip of tea. Herbal teas have plenty of benefits for body and mind and it helps in balancing chakras within. Wheels of human body In Sanskrit, Chakras or ‘wheels’ are centres of illimitable energy located in the human body. Align all seven chakras of the body with each other and balance their energies. Transmute your cup of Mrija into your tranquilly mindful moment of the day.Everything that surrounds us is made of energy, including our bodies. Chakras are the focal point of energy in our bodies and the word itself means “wheel” in Sanskrit. Mrija teas have plenty of benefits for body and mind and it helps in balancing chakras within. Handpicked, withered, rolled and fermented; our teas are made by experienced tea makers using traditional processes. </p>
</>




    }

    // open product in popup
    function openProductInPopup(e, prodName){
        let imagePath = e.target.parentNode.parentNode.children[0].firstChild.src; 
        console.log(imagePath); 
        setProduct({
            name: prodName, 
            image: imagePath, 
            content: content.prodName
        }); 

        let status = prodModel === true? false:true; 
        setProdModel(status); 
    }

    function closeModal(e) {
            setProdModel(false); 
    }

    function shutModal(e){
        if(e.target.className === "modal"){
            setProdModel(false); 
        }
    }

    


    // Modal product content

    const productContent  = { 
        Mrija: "",
        Japa: "",  
        Jheel: "", 
        Svarga: "", 
        Jnana: "", 
        Nuqi: "",
    }




    return(
        <section id="page-products">
        <div className='products-header'>
             <h1> PRODUCTS</h1>
        </div>
        
        {/* products section start*/}

        <div className='component-product'>
        <div className='container'>
            <h2 className='section-title'>Our <span>INVENTIONS</span></h2>
            <p>We take pride in its extensive research and development efforts, which enable them to create products that are not only high-quality but also sustainable and environmentally friendly.</p>

            <div className='products-wrapper'>
            <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/blur-shoe.png'/>
                    </div>

                    <div className='product-footer'>
                        <div className='prod-desc'>
                        <h2>OwnShoe</h2>
                        <p> Our latest innovation Own Shoe - a cutting-edge shoe designed to enhance your active lifestyle.</p>
                        </div>
                       
                        <button className='btn btn-primary'onClick={e => openProductInPopup(e, "OwnShoe")}>Read More</button>
              

                    </div>
                </div>

                {/* <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/Cartesia-1.png' />
                    </div>

                    <div className='product-footer'>
                        <Link to={`/products/avyana`}>Avyana</Link>
                        <p>A frangrance for the extrasensory explorer liberate its imbiber from the taint of
                             the elemensts.</p>
                        <button className='btn btn-primary'onClick={e => openProductInPopup(e, "avyana")}>Read More</button>

                    </div>
                </div> */}

                <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/mrija-1.png' />
                    </div>

                    <div className='product-footer'>
                    <h2>Mrija</h2>
                        <p>The majestic herbs of this tea designate to liberate its imbiber from the taint of the elements. {/*It betokens to liberate from the physical.'Chakra Balancing' is a 
                            rudimental sadhana in yoga to transcend the inhibitions of the physical and to become 
                            available to a dimension beyond the physical. */}
                        </p>
                        <button className='btn btn-primary'onClick={e => openProductInPopup(e, "Mrija")}>Read More</button>

                    </div>
                </div>


                <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/Japa.png'/>
                    </div>

                    <div className='product-footer'>
                    <h2>Japa</h2>
                        <p>Tactus<sup>®</sup> has created a dynamic tool that not only keeps track of the time but also helps you "dial it down".


                        </p>
                        <button className='btn btn-primary'onClick={e => openProductInPopup(e, "Japa")}>Read More</button>
                        

                    </div>
                </div>

                <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/Jheel-1.png' />
                    </div>

                    <div className='product-footer'>
                    
                        <h2>Jheel</h2>
    
                        <p>
                            A traditional liquor distilled from the fermented juice of various fruits.
                        </p>
                        
                        <button className='btn btn-primary'onClick={e => openProductInPopup(e, "Jheel")}>Read More</button>
                      
                    </div>
                </div>

                <div className='product-card' >
                    <div className='product-showcase'>
                       <img src='assets/img/products/svarga.png' />
                    </div>

                    <div className='product-footer'>
                    <h2>Svarga</h2>
                        <p>Planet with planet. Humanity with divinity. 
                        </p>
                        <button className='btn btn-primary'onClick={e => openProductInPopup(e, "Svarga")}>Read More</button>

                    </div>
                </div>

                <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/Jnana.png' />
                    </div>

                    <div className='product-footer'>
                    <h2>Jnana</h2>
                        <p>A backflow incense burner for sweet bakhoor.</p>
                        <button className='btn btn-primary'>Coming Soon</button>

                    </div>
                </div>


                <div className='product-card'>
                    <div className='product-showcase'>
                       <img src='assets/img/products/nuqi-1.png' />
                    </div>
                            
                    <div className='product-footer'>
                    <h2>Nuqi</h2>
                        <p>Details coming soon.</p>
                        <button className='btn btn-primary'onClick={e => (e, "Nuqi")}>Coming Soon</button>

                    </div>
                </div>

                

            </div>

        </div>
    </div>

    {/* product section end */}

    <div className='modal' onClick={e => shutModal(e)}  style={modalStyle}>
    <div className='prod-modal'>
        <button onClick={ e => closeModal(e)} className='times-btn-modal'>&times; </button>
        <h2 className='prod-title'>{product.name}</h2>
        <div className='product-content'>   
            <div className='prod-img'>
                <img src={product.image}/>           
            </div>

            <div className='prod-desc'>

                {content[product.name]}
                
                {/* <p><h2 className='mrija'>Mrija®</h2><br></br><span className='meaning'>Meaning purification,cleansing and ablution.</span> <br></br>
                   Mrija is a tea line product of Tactus Ventures.
                   The majestic herbs of this tea designate to liberate its imbiber from the taint of the elements.<br></br><span className='chakra'>'Chakra Balancing'</span><br></br>is a rudimental sadhana in yoga to transcend the inhibitions of the physical and to become available to a dimension beyond the physical.<br></br> <h3>Why Mrija?</h3> <br></br> Tea was pristinely a medicine and later grew into the popular beverage it is today. Its protective health benefits cannot be stressed enough as modern man has withal discovered its potency of antioxidants.<br></br>
                   <h3>Transcend beyond the realm of imagination</h3><br></br> Give your body the time, space and perfect conditions to recuperate balance and vitality with every sip of tea. Herbal teas have plenty of benefits for body and mind and it helps in balancing chakras within. Wheels of human body In Sanskrit, Chakras or ‘wheels’ are centres of illimitable energy located in the human body. Align all seven chakras of the body with each other and balance their energies. Transmute your cup of Mrija into your tranquilly mindful moment of the day.Everything that surrounds us is made of energy, including our bodies. Chakras are the focal point of energy in our bodies and the word itself means “wheel” in Sanskrit. Mrija teas have plenty of benefits for body and mind and it helps in balancing chakras within. Handpicked, withered, rolled and fermented; our teas are made by experienced tea makers using traditional processes. </p> */}

            </div>
        </div>  
    </div>
    </div>

    </section>
    )

}

export default Products; 