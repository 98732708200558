import { Link } from 'react-router-dom';
import './Blog.css';

const Blog = () => {
    return(
        <section id="page-blog">

            <div className='blog-header'>
             <h1> Blogs</h1>
            </div>

            <div className='section-blog'>
                <div className='container'>
                    <h2 className='section-title'>No article's Published Yet <span></span> </h2>

                    {/* <div className='blog-articles'>
                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/Capture-1.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a>

                                <div className='blog-footer'>
                                    <Link to ='../Blog/SingleBlog' className='read-more-btn'>Read More</Link>
                                </div>
                            </div>
                        </div>

                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/Capture.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a> 


                                   <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div> 
                            </div>
                        </div>


                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/oxyzo-fintech-team.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a>

                                <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/oxyzo-fintech-team.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a>

                                <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div>
                            </div>
                        </div>

                        <div className='article'>
                            <img src='https://d86af0gufpxog.cloudfront.net/unsafe/410x238/https://blog.ofbusiness.com/wp-content/uploads/2022/03/oxyzo-fintech-team.png' alt="blog-image" />

                            <div className='blog-content'>
                                <a href='#' className='blog-title'>OfBusiness financial arm Oxyzo raises $200 mn in Series A funding round</a>

                                <div className='blog-footer'>
                                    <a href='#' className='read-more-btn'>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default Blog;  