import { Link } from 'react-router-dom';
import './SingleBlog.css';


const SingleBlog = () => {
    return(
        <section id='page-single-blog'>
            <div className='first-image'>
                <div className='container'>
                    <img src='/assets/img/blog-post-1.jpg'></img>
                    Panorama from the ridge walk in Ubud, Bali.
                </div>
            </div>

            <div className='heading'>
                <div className='container'>
                    <h1 className='head'>Lorem ipsum dolor sit amet, consectetur</h1>
                </div>
                <div className='content'>
                <p>Eed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium sit voluptatem accusantium </p>
                </div>
            </div>

            <div className='blog-content'>
                <div className='container'>
                <p>Every waking, breathing moment of my life hurt, and then some. It had been that way for so 
                long that I was beginning to forget what it felt like not to be in pain. Regardless of what 
                task I was engaged in, even while I was trying to relax or sleep, that awful bitch called 
                chronic pain was consistently tormenting me, and not a doctor or specialist for miles could 
                convincingly explain why.
                </p>
                <p>
                It finally dawned on me one morning—as I peeled myself out of bed after another sleepless, 
                excruciating night—that if everything I did hurt than maybe it was time to change everything.
                </p>
                <p>
                So I started making big plans for my grand exodus, what I’d later discover the Hindu’s call a 
                “Quantum Leap.”
                </p>
                <p>
                Merriam-Webster describes a Quantum Leap as “an abrupt change, sudden increase, or dramatic 
                advance” while students of the yogic traditions have a more complex definition:
                </p>
                <p>
                “This movement from one state of consciousness to another seems frequently to be characterized 
                by two primary factors, one of acute inner tension and spiritual anguish followed by a sudden 
                and often dramatic release or surrender of the personality to a higher aspect of consciousness.”
                </p>
                <p>
                It took me years to fully wrap my head around it, but the following seemingly simple concept 
                has since become the guiding principle on my chronic pain recovery journey:
                </p>
                <p>
                My dis-ease and my pain is not a curse but is actually a beacon. It is a compass that will lead 
                me towards the aspirations of my essential nature; to a more peaceful, authentic, healthy and 
                fulfilling life.
                </p>
                </div>
            </div> 

            <div className='middle-image'>
                <div className='container'>
                    <img src='/assets/img/im1.jpg'></img>
                    <img src='/assets/img/im1.jpg'></img>
                    <img src='/assets/img/im1.jpg'></img>
                </div>
            </div>      
        </section>
    )
}

export default SingleBlog;