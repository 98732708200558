import {Link} from 'react-router-dom';
import { useState } from 'react';

import "./header.css"; 

const Header = () => {
    const  [active, setActive] = useState(false); 
    function activeNavigation() {
        let ul = document.querySelector('#component-header .header-right ul'); 
        ul.style.display = active?"block": "none";
        if(active){
            ul.style.right = 0; 
        } 
        setActive((active) => !active); 
    }

    function deactivateNavigation(e){
        let ul = document.querySelector('#component-header .header-right ul'); 
        ul.style.display = "none"; 
        
    }

    return(
        <header id="component-header">
            <div className='container'>
                <div className="header-left"> 
                    <Link to="/"> 
                        <img src='/assets/img/logo.png' alt='tactus-ventures-logo' />
                        <h2>TACTUS<sup className='sup-r'>®</sup> VENTURES INC</h2>
                        {/* <h2>Tactus <sup className='sup-r'>R</sup> Ventures Inc</h2> */}
                    </Link>
                </div>  

                <div className="header-right"> 
                    <ul className='active'>
                        <li>
                            <Link to="/products">Products</Link>
                        </li>

                        <li>
                            <Link to="/about-us">About</Link>
                        </li>
                         
                        <li>
                            <Link to="/contact-us">Contact</Link>
                        </li>

                        <li>
                            <Link to="/blog">Blog</Link>
                        </li>
                        
                        <i className='fas fa-times  times' onClick={e => deactivateNavigation(e)}></i>
                    </ul>

                    <i className='fas fa-bars  bars' onClick={e => activeNavigation(e)}></i>
                </div>
            </div>
        </header>
    )
}

export default Header; 