import React, {useState} from 'react'; 
import './contact.css'; 
import { Notyf } from 'notyf'


const Contact = () => {

  const notyf = new Notyf({
    duration: 2000,
    position: {
      x: 'right',
      y: 'top',
    },
  });

  const [contact, setContact] = useState({
    name: "", 
    email: "", 
    mobile: "", 
    message: ""
  }); 

  function submitMessage(e){
    e.preventDefault(); 
    console.log(name, email, mobile, message); 
    if(!name || !email || !mobile || !message){
      notyf.error("something went wrong"); 
    }else{
      notyf.success('Thanks, Messages has been Sent successfully'); 
    }
  }



  const {name, email, mobile, message} = contact; 
  return (
    <section id="page-contact">
        <div className='contact-header'>
             <h1>Contact Us</h1>
        </div>

        <div className='contact'>
             <div className='container'>
                <div className='contact-header-content'>
                  <h2>Get In Touch</h2>
                  <p> <strong>Phone: </strong>  022 69368000</p>
                  <p><strong> Email: </strong> info@tactusventures.com</p>
                  
                </div>

                <form>
                  <div className='form-wrapper'>
                      <div className='form-left'>
                          <div className='form-group'>
                            <input type="text" placeholder='Name *' value={name} name='name' 
                            onChange={e => setContact({...Contact, [e.target.name]: e.target.value})}
                            
                            required/>
                          </div>

                          <div className='form-group'>
                            <input type="email" placeholder='Email *' value={email} name='email' 
                             onChange={e => setContact({...Contact, [e.target.name]: e.target.value})}
                            required/>
                          </div>

                          <div className='form-group'>
                            <input type="number" placeholder='Mobile Number *' name='mobile' value={mobile} 
                             onChange={e => setContact({...Contact, [e.target.name]: e.target.value})} required/>
                          </div>
                      </div>

                      <div className='form-right'>
                          <div className='form-group'>
                            <textarea name='message' value={message
                            } placeholder='Message *'  column="20" 
                            onChange={e => setContact({...Contact,[e.target.name]: e.target.value})}
                            ></textarea>
                          </div>
                       </div>
                  </div>

                  <button onClick={e => submitMessage(e)} className='btn btn-primary'>Send Message</button>
                </form>
             </div>
        </div>


        <div className='our-offices'>
            <div className='container'>
              <h2 className='section-title'>Our Office's Around the Globe</h2>

              <div className='offices-wrapper'>
                  <div className='office'>

                    <h2>HeadQuarters USA:</h2>
                     
                     <div className='office-card'>
                        <div className='office-img'>
                            <img src='/assets/img/us-office.jpg' alt='mumbai-office' />
                        </div>

                        <div className='office-content'>
                          <h2>United States Of America</h2>
                            <p><strong>Address</strong>: 
16192 Coastal Highway, Lewes, Delaware 19958, 
, United States of America </p>
                            <div className='office-contact'>
                          
                              <p>
                                Support:<span>+91 9619989195</span>
                              </p>
                              <p>
                                Email:<span>info@tactusventures.com</span>
                              </p>
                            </div>
                        </div>
                     </div>
                  </div>

                  <div className='office'>
                    <h2>India Office:</h2>
                    
                    <div className='office-card'>
                        <div className='office-img us-office-img'>
                            <img src='/assets/img/mumbai-office.jpg' alt='mumbai-office' />
                        </div>

                        <div className='office-content'>
                          <h2>Mumbai</h2>
                            <p><strong>Address</strong>: Compound No 6, Plot No 28, 
Marol Co-operative, Industrial Istate, 
Opp prodocs Solutions Pvt. Ltd, 
Marol, Andheri-East, 
Mumbai - 4000059, Maharashtra</p>
                            <div className='office-contact'>
                            
                                  <p>
                                    Support: &nbsp;&nbsp;&nbsp;<span>  022 69368000</span>
                                  </p>
                                  <p>
                                    Email:&nbsp;&nbsp;&nbsp; <span> info@tactusventures.com</span>
                                  </p>
                                </div>
                        </div>
                    </div>
                    </div>
              </div>
            </div>
        </div>
    </section>
  )
}

export default Contact
